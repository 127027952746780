<template>
    <div class="EvaluationObjectTable">
        <div class="first-title">评估对象</div>
        <div class="search-box mb27">
            <el-form ref="searchForm" :model="searchParams" inline :disabled="$route.query.handleType === 'see'">
                <el-form-item label="应用场景">
                    <el-select v-model="searchParams.applicationType" placeholder="请选择" clearable @change="changeScene">
                        <el-option v-for="item in SCENEOBJECT" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" v-if="$route.query.handleType !== 'see'" @click="doModel">模板下载</el-button>
                <el-button type="primary" plain v-if="$route.query.handleType !== 'see'" @click="visibleUpload = true">导入</el-button>
            </div>
        </div>
        <el-table
            ref="multipleTable"
            v-loading="loading"
            style="width: 100%; margin-top: 10px"
            :data="tableData"
            border
            :header-cell-style="headerCellStyle"
            :row-key="
                row => {
                    return row.phone
                }
            "
            @select-all="handleAllChange"
            @select="handleSelectionChange"
        >
            <template slot="empty">
                <IsEmpty />
            </template>
            <el-table-column v-if="$route.query.handleType !== 'see'" type="selection" width="55" :reserve-selection="true">
            </el-table-column>
            <el-table-column type="index" label="序号" width="50" align="center" />
            <el-table-column prop="userName" label="评估对象" align="center" />
            <el-table-column prop="phone" label="手机" show-overflow-tooltip align="center" />
            <el-table-column prop="sex" label="性别" align="center">
                <template slot-scope="scope">
                    <span>{{ getContent(SEXOPTIONS, scope.row.sex, 'label', 'text') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="birthday" label="出生日期" show-overflow-tooltip align="center" />
            <el-table-column prop="homeAddress" label="家庭地址" show-overflow-tooltip align="center" />
            <el-table-column prop="pop" label="操作" width="200" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" v-if="scope.row.see === 0 && $route.query.handleType === 'see'" @click="toDetail(scope.row)">
                        查看评估结果
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @size-change="getTableData"
            @current-change="getTableData"
            :current-page.sync="queryParams.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size.sync="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
        >
        </el-pagination>
        <!-- 新增/编辑评估对象 -->
        <EvaluationObjectHandle
            v-if="dialogVisible"
            :dialogVisible.sync="dialogVisible"
            @addObject="addObject"
            @editObject="editObject"
            :objectType="objectType"
            :currentObject="currentObject"
        />
        <!-- 导入 -->
        <UploadFile :uploadVisible.sync="visibleUpload" @updateList="updateList" :url="uploadUrl" />
    </div>
</template>

<script>
import EvaluationObjectHandle from './EvaluationObjectHandle.vue'
import { unique, deepClone, downloadStream, getContent } from '@/utils/index'
import UploadFile from '@/components/uploadFile'
import { SCENEOBJECT, SEXOPTIONS } from '@/data/dataDictionary'
import { getObjectPage, downloadExcel } from '@/api/evaluationProject'
export default {
    // 评估对象表格
    name: 'EvaluationObjectTable',
    components: {
        EvaluationObjectHandle,
        UploadFile
    },
    props: {
        editObjectList: {
            type: Array,
            default: () => {
                return []
            }
        },
        sceneId: {
            type: [Number, String],
            default: null
        }
    },
    watch: {
        editObjectList: {
            handler(val) {
                const list = deepClone(val)
                if (list.length > 0) {
                    this.allList = deepClone(list)
                    this.allList.forEach(item => {
                        item.isCheck = true
                    })
                    this.getList()
                }
            },
            deep: true,
            immediate: true
        },
        sceneId: {
            handler(val) {
                this.searchParams.applicationType = val
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            SCENEOBJECT, // 场景
            SEXOPTIONS, // 性别
            loading: false,
            searchParams: {
                applicationType: ''
            },
            queryParams: {
                pageNum: 1,
                pageSize: 10
            },
            total: 1,
            tableData: [],
            dialogVisible: false,
            allList: [], // 全部数据
            sceneList: [], // 场景选择数据
            uploadList: [], // 上传数据
            objectType: 'add',
            currentObject: null,
            uploadUrl: '/assessEvaluationProject/objectImport',
            visibleUpload: false
        }
    },
    methods: {
        getContent,
        // 设置选中
        setCheck(list) {
            list.forEach(item => {
                if (this.allList.indexOf(item) !== -1) {
                    const index = this.allList.indexOf(item)
                    this.allList[index].isCheck = true
                }
            })
        },
        // 单个选择
        handleSelectionChange(selection, row) {
            console.log(selection, row, 'handleSelectionChange')
            // this.$message.info(`当前总共选中${selection.length}条数据`)
            this.allList.forEach(item => {
                if (item.phone === row.phone) {
                    item.isCheck = !item.isCheck
                }
            })
            this.getSubmitData()
        },
        // 全选
        handleAllChange(val) {
            console.log(val, 'handleAllChange')
            // this.$message.info(`当前总共选中${val.length}条数据`)
            this.setCheck(val)
            this.getSubmitData()
        },
        // 获取场景下的对象数据
        changeScene(val) {
            this.queryParams.pageNum = 1
            this.sceneList = []
            this.allList = []
            this.$refs.multipleTable.clearSelection()
            if (val) {
                this.loading = true
                getObjectPage({ type: val })
                    .then(res => {
                        this.sceneList = res.data
                        this.getList()
                    })
                    .catch(() => {
                        this.loading = false
                    })
            } else {
                this.sceneList = []
            }
            this.getSubmitData()
        },
        // 获取选择数据
        getSubmitData() {
            const array = this.allList.filter(item => {
                if (item.isCheck) {
                    return item
                }
            })
            console.log(array, 'getSubmitData')
            this.$emit('updateObject', array, this.searchParams)
        },
        // 获取所有数据
        getList() {
            const add = unique(this.sceneList.concat(this.uploadList), 'phone')
            this.allList = unique(this.allList.concat(add), 'phone') // 回显数据
            this.getTableData()
            this.getSubmitData()
            this.loading = false
        },
        // 获取表格数据
        getTableData() {
            console.log('getTableData>>>>>>>>>>>>>>')
            this.total = this.allList.length
            this.tableData = this.allList.slice(
                (this.queryParams.pageNum - 1) * this.queryParams.pageSize,
                this.queryParams.pageNum * this.queryParams.pageSize
            )
            // 回显表格是否选中
            this.tableData.forEach(item => {
                if (item.isCheck) {
                    this.$refs.multipleTable.toggleRowSelection(item, true)
                }
            })
        },
        doHandle(type, row) {
            console.log(type, row, 'doHandle')
            this.objectType = type
            this.currentObject = row
            this.dialogVisible = true
        },
        // 评估模板下载
        async doModel() {
            const res = await downloadExcel({ fileName: '评估对象模板.xlsx' })
            downloadStream(res, `评估对象模板.xlsx`)
        },
        // 评估对象导出
        // async doExport() {
        //     const res = await objectExport({ id: this.$route.query.id })
        //     downloadStream(res, `评估对象.xlsx`)
        // },
        // 导入评估对象
        updateList(data) {
            const list = data.list[0]
            console.log(list, 'updateList')
            if (list.length > 0) {
                this.uploadList = unique(this.uploadList.concat(list), 'phone')
                this.getList()
            }
        },
        // 查看评估结果
        toDetail(row) {
            this.$router.push({
                path: '/evaluationProject/computerTemplate',
                query: {
                    projectId: this.$route.query.id,
                    objectId: row.id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.EvaluationObjectTable {
    width: 80%;
    .search-box {
        display: flex;
        justify-content: space-between;
    }
    .first-title {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .mb27 {
        margin-bottom: 27px;
    }
}
</style>
