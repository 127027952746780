// 数据字典 dataDictionary

/**
 * 床位建设--建设状态
 */
export const BUILDSTATE = [
    {
        label: '待建设',
        value: 1
    },
    {
        label: '建设中',
        value: 2
    },
    {
        label: '验收中',
        value: 3
    },
    {
        label: '整改中',
        value: 4
    },
    {
        label: '建设完成',
        value: 5
    }
]

/**
 * 床位建设--完整床位建设状态
 */
export const BUILDALLSTATE = [
    {
        label: '待建设',
        value: 1
    },
    {
        label: '建设中',
        value: 2
    },
    {
        label: '验收中',
        value: 3
    },
    {
        label: '整改中',
        value: 4
    },
    {
        label: '建设完成',
        value: 5
    },
    {
        label: '停止服务',
        value: 6
    }
]

/**
 * 床位建设--老人能力
 */
export const ELDERLYABILITY = [
    {
        label: '重度失能',
        value: 3
    },
    {
        label: '中度失能',
        value: 2
    },
    {
        label: '轻度失能',
        value: 1
    },
    {
        label: '能力完好',
        value: 0
    }
]

/**
 * 床位建设--老人类型
 */
export const ELDERLYTYPE = [
    {
        label: '除低保、特困之外的其他低收入老年人',
        value: 1
    },
    {
        label: '低保',
        value: 0
    },
    {
        label: '普通老年人',
        value: 2
    }
]

/**
 * 订单状态
 */
export const STATUSOPTIONS = [
    { label: '待分配', value: 0 },
    { label: '已取消', value: 1 },
    { label: '已完成', value: 2 },
    { label: '服务中', value: 3 },
    { label: '待服务', value: 4 }
]

/**
 * 服务类型
 */
export const SERVICETYPEOPTIONS = [
    { label: '服务券兑换', value: 1 },
    { label: '自费', value: 0 }
]

/**
 * 异常状态
 */
export const EXCEPTIONOPTIONS = [
    { label: '正常', value: 0 },
    { label: '异常', value: 1 }
]

/**
 * 签约状态
 */
export const SIGNOPTIONS = [
    { label: '已签约', value: 2 },
    { label: '未签约', value: 1 }
]

/**
 * 服务状态
 */
export const SERVICEOPTIONS = [
    { label: '待服务', value: 2 },
    { label: '服务中', value: 1 }
]

/**
 * 公单状态
 */
export const WORKOPTIONS = [
    { label: '待确认', value: 1 },
    { label: '待分配', value: 2 },
    { label: '待服务', value: 3 },
    { label: '服务中', value: 4 },
    { label: '已完成', value: 5 },
    { label: '已取消', value: 6 }
]
export const AUDITSTATE = [
    { label: '暂存', value: 0 },
    { label: '待审核', value: 1 },
    { label: '审核通过', value: 2 },
    { label: '审核不通过', value: 3 }
]
export const SERVICECONTENT = [
    { label: '助老服务', value: 1 },
    { label: '信息服务', value: 2 }
]

export const SEXLIST = [
    { label: '未知的性别', value: 0 },
    { label: '男性', value: 1 },
    { label: '女性', value: 2 },
    { label: '未说明的性别', value: 9 }
]

/**
 * 性别
 */
export const SEXOPTIONS = [
    { label: '未知的性别', value: 0 },
    { label: '男', value: 1 },
    { label: '女', value: 2 },
    { label: '未说明的性别', value: 9 }
]

/**
 * 养老评估
 */

// 场景对象

export const SCENEOBJECT = [
    { label: '机构补贴', value: 1 },
    { label: '家庭养老床位', value: 2 },
    { label: '养老服务监管', value: 3 },
    { label: '民政居家养老服务', value: 4 }
]
/**
 * 高龄补贴
 */

//   身份类别 Identity category
export const IDCATEGORY = [
    { label: '普通老年人', value: 0 },
    { label: '低保老年人', value: 1 },
    { label: '失能老年人', value: 2 },
    { label: '低保失能老年人', value: 3 }
]

/**
 * 风险事件类型
 */

export const RISKTYPE = [
    { label: '全部', value: '全部' },
    { label: '防盗风险', value: 0 },
    { label: '火灾风险', value: 1 },
    { label: '跌倒风险', value: 2 },
    { label: '活跃异常', value: 3 },
    { label: '走丢风险', value: 4 },
    { label: '紧急风险', value: 5 },
    { label: '燃气泄漏', value: 6 },
    { label: '水浸风险', value: 7 },
    { label: '健康风险', value: 8 }
]

/**
 * 设备名称--智慧居家安全
 */

export const EQUNAME = [
    { label: 'NB-IoT紧急呼叫按钮', value: 'NB-IoT紧急呼叫按钮' },
    { label: 'NB-IoT门磁报警器', value: 'NB-IoT门磁报警器' },
    { label: 'NB-IoT红外探测报警器', value: 'NB-IoT红外探测报警器' }
]
