<template>
  <el-dialog title="批量导入" :visible.sync="uploadVisible" width="538px" :before-close="handleClose"
             :close-on-click-modal="false">
    <div class="myUpload">
      <span style="color: #333; font-size: 16px; margin-right: 28px; margin-top: 10px">选择上传文件</span>
      <el-upload
        ref="upload"
        class="upload-demo"
        accept=".xls,.xlsx"
        :action="uploadUrl"
        :data="uploadData"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeAvatarUpload"
        :file-list="fileList"
        :auto-upload="false"
        :headers="myHeaders"
        :limit="1"
        center
      >
        <el-button slot="trigger" type="primary">选取文件</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="submitUpload">上传到服务器</el-button>
      </el-upload>
    </div>
    <div class="el-upload-tip">只能上传.xls,.xlsx格式的文件</div>
  </el-dialog>
</template>

<script>
  import config from '@/api/config'

  export default {
    // 上传文件
    name: 'uploadFile',
    props: {
      uploadVisible: {
        type: Boolean,
        default: false
      },
      uploadData: {
        // 上传其他数据
        type: Object,
        default: () => {
          return {}
        }
      },
      type: {
        type: String
      },
      url: {
        // 上传接口 -- 默认机构备案接口
        type: String,
        default: '/civilAffairsInstitutionalFiling/importTemplate'
      }
    },
    computed: {
      uploadUrl() {
        return config.CIVIL_HOST + this.url
      }
    },
    data() {
      return {
        fileList: [],
        myHeaders: {Authorization: JSON.parse(localStorage.getItem('cad_userInfo'))?.token}
      }
    },
    methods: {
      handleClose() {
        this.$refs.upload.clearFiles()
        this.$emit('update:uploadVisible', false)
      },
      beforeAvatarUpload(file) {
        console.log(file, 'file')
        const isXLS =
          file.type === 'application/vnd.ms-excel' ||
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        if (!isXLS) {
          this.$message.error('请选择正确的文件格式上传')
        }
        return isXLS
      },
      // 上传成功回调
      handleSuccess(res) {
        if (res.state === 0) {
          this.handleClose()

          if (this.type && this.type === 'elderly') {
            this.$alert(
              `
                    <div >
                  <p>新增成功：${res.data.addNum || 0}条</p>
                <p>更新成功：${res.data.updateNum || 0}条</p>
                <p>导入失败：${res.data.failNum || 0}条</p>
                <p>失败行号：${res.data.failRows || '暂无'}</p>
                </div> `,
              '导入',
              {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '知道了'
              }
            )
          } else {
            this.$alert(
              `
                    <div>
                        <p>新增成功：${res.data.successCount || 0}条</p>
                        <p>更新成功：${res.data.updateCount || 0}条</p>
                        <p>导入失败：${res.data.errorCount || 0}条</p>
                        <p>失败行号：${res.data.errorRows && res.data.errorRows.length > 0 ? res.data.errorRows.join(',') : '暂无'}</p>
                    </div> `,
              '导入',
              {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '知道了'
              }
            )
          }

          this.$emit('updateList', res.data)
        } else {
          this.$message.error(res.message)
          this.$refs.upload.clearFiles()
        }
      },
      // 上传失败回调
      handleError(res) {
        if (res.state === 10011) {
          this.$message.error(res.message)
          return
        }
        this.$message.error('上传失败，请检查数据是否符合规则')
        this.$refs.upload.clearFiles()
        this.handleClose()
      },
      async submitUpload(file) {
        await this.$refs.upload.submit()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .myUpload {
    width: 100%;
    padding: 40px 25px;
    display: flex;
    // align-items: center;
  }

  .el-upload-tip {
    font-size: 12px;
    font-weight: 400;
    color: #9e9e9e;
    text-align: center;
    margin-bottom: 72px;
  }
</style>
