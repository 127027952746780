import request from '../utils/request'
import config from './config'
// 养老评估

// 评估项目列表
export function evaluationProjectList(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/list`,
        method: 'get',
        params
    })
}

// 评估项目启用、禁用
export function govern(data) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/govern`,
        method: 'post',
        data
    })
}

// 评估项目导出
export function projectExport(data) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/projectExport`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 下级评估机构
export function assessUnit(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/assessUnit`,
        method: 'get',
        params
    })
}

// 评估项目详情
export function evaluationProjectDetails(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/details`,
        method: 'get',
        params
    })
}

// 根据场景查询对象信息
export function getObjectPage(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/getObjectList`,
        method: 'get',
        params
    })
}

// 评估对象导出
export function objectImport(data) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/objectImport`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 评估项目模板下载
export function downloadExcel(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/downloadExcel`,
        method: 'get',
        params,
        responseType: 'blob'
    })
}

// 新增评估项目
export function evaluationProjectSave(data) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/save`,
        method: 'post',
        data
    })
}

// 评估档案-分页查询列表
export function assessorObjectPage(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/assessorObjectPage`,
        method: 'get',
        params
    })
}

// 评估档案-根据评估对象查询评估项目列表
export function projectList(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/projectList`,
        method: 'get',
        params
    })
}

// 查询评估档案详情
export function questionnaireDetail(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/questionnaireDetail`,
        method: 'get',
        params
    })
}

// 评估结果报告下载
export function reportExport(params) {
    return request({
        url: `${config.CIVIL_HOST}/assessEvaluationProject/report/export`,
        method: 'get',
        params,
        responseType: 'blob'
    })
}
